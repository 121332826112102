{
  "auth": {
    "email": "Email",
    "login": "Login",
    "password": "Password",
    "emailValid": "Please enter a valid email address",
    "loggedIn": "You've successfully logged in",
    "enable2fa": "Enable 2FA",
    "verify2fa": "Verify 2FA",
    "disable2fa": "Disable 2FA",
    "enter2faCode": "Enter 6 Digit 2FA Code Here",
    "email_or_username": "Email / Username"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
    "backButton": "Go to homepage"
  },
  "menu": {
    "home": "Home",
    "auth": "Auth",
    "buttons": "Buttons",
    "dashboard": "Dashboard",
    "login": "Login",
    "logout": "Logout",
    "account": "Account",
    "settings": "Settings",
    "userSettings": "User Settings",
    "users": "Users",
    "companies": "Companies",
    "clients": "Clients",
    "rejectionMessages": "Rejection Messages",
    "bankAccounts": "Bank Accounts",
    "banks": "Banks",
    "vendors": "Vendors",
    "finance": "Finance",
    "reports": "Reports",
    "clientTransactions": "Client Transactions",
    "companyTransactions": "Company Transactions",
    "roles": "Role Management",
    "playerTransactions": "Player Transactions",
    "clientReports": "Client Reports",
    "companyReports": "Company Reports",
    "companyLimitReport": "Company Limit Report",
    "marsDepositReport": "Mars Deposit Report",
    "callbackLogs": "Callback Logs",
    "newManualDeposit": "New Manual Deposit",
    "newManualWithdraw": "New Manual Withdraw",
    "clientGroups": "Client Groups",
    "companyBalances": "Company Balances",
    "clientBalances": "Client Balances",
    "depositWithdrawReports": "Deposit Withdraw Reports",
    "notifications": "Notifications",
    "excelExport": "Excel Export"
  },
  "user": {
    "language": "Change Language",
    "logout": "Logout",
    "profile": "Profile",
    "settings": "Settings",
    "account": "Account"
  },
  "theme": {
    "dark": "Dark",
    "light": "Light"
  },
  "language": {
    "en": "EN",
    "tr": "TR"
  },
  "filter": {
    "search": "Search",
    "sort": "Sort",
    "bank": "Bank",
    "status": "Status",
    "OTPstatus": "OTP Status",
    "depositStatus": "Deposit Status",
    "withdrawStatus": "Withdraw Status",
    "depositLimit": "Deposit Limit",
    "depositAmount": "Deposit Amount",
    "depositCommissionRate": "Deposit Commission Rate",
    "withdrawCommissionRate": "Withdraw Commission Rate",
    "clients": "Clients",
    "companies": "Companies",
    "userType": "User Type",
    "type": "Type"
  },
  "form": {
    "id": "Id",
    "name": "Name",
    "surname": "Surname",
    "email": "Email",
    "username": "Username",
    "password": "Password",
    "passwordConfirm": "Password Confirm",
    "iban": "IBAN",
    "OTPcode": "OTP Code",
    "status": "Status",
    "userStatus": "User Status",
    "OTPstatus": "OTP Status",
    "userType": "User Type",
    "company": "Company",
    "role": "Role",
    "client": "Client",
    "clientGroup": "Client Group",
    "redirectUrl": "Redirect Url",
    "redirectType": "Redirect Type",
    "depositStatus": "Deposit Status",
    "withdrawStatus": "Withdraw Status",
    "depositType": "Deposit Type",
    "withdrawType": "Withdraw Type",
    "vendor": "Vendor",
    "bank": "Bank",
    "bankCode": "Bank Code",
    "fastLimitAmount": "Fast Limit Amount",
    "minAmount": "Min Amount",
    "maxAmount": "Max Amount",
    "type": "Type",
    "description": "Description",
    "depositLimit": "Deposit Limit",
    "depositAmount": "Deposit Amount",
    "depositCommissionRate": "Deposit Commission Rate",
    "withdrawCommissionRate": "Withdraw Commission Rate",
    "merchantToken": "Merchant Token",
    "webhookUrl": "Webhook Url",
    "withdrawProtectLimit": "Withdraw Protect Limit",
    "fastLimit": "Fast Limit",
    "fastMinDepositAmount": "Fast Min Deposit Amount",
    "advanceLimitAmount": "Advance Limit Amount",
    "startDate": "Start Date",
    "bet_provider_txid": "Bet Provider TX Id",
    "player_username": "Player Username",
    "sender_fullname": "Sender Fullname",
    "sender_iban": "Sender Iban",
    "receiver_fullname": "Receiver Fullname",
    "receiver_iban": "Receiver Iban",
    "amount": "Amount",
    "created_at": "Created At",
    "updated_at": "Updated At",
    "report_date": "Report Date",
    "bank_code": "Bank Code",
    "google2faEnable": "2FA Enable",
    "google2faDisable": "2FA Disable",
    "declineReason": "Decline Reason",
    "user": "User",
    "logo": "Logo",
    "commissionRate": "Commission Rate",
    "limit": "Limit",
    "http": "http://",
    "chooseLogo": "Choose Logo",
    "logoPreview": "Logo Preview",
    "minDepositAmount": "Min Deposit Amount",
    "maxDepositAmount": "Max Deposit Amount",
    "playerUsername": "Player Username",
    "playerFullname": "Player Name",
    "senderFullName": "Sender Name",
    "playerId": "Player Id",
    "sumDeposit" : "Total Deposit",
    "countDeposit" : "Total Deposit Count",
    "sumDepositCom" : "Total Deposit Commission",
    "sumDepositNet" : "Total Deposit Net",
    "sumWithdraw" : "Total Withdraw",
    "countWithdraw" : "Total Withdraw Count",
    "sumWithdrawCom" : "Total Withdraw Commission",
    "sumWithdrawNet" : "Total Withdraw Net",
    "sumManualDeposit" : "Total Manual Deposit",
    "countManualDeposit" : "Total Manual Deposit Count",
    "sumManualDepositCom" : "Total Manual Deposit Commission",
    "sumManualDepositNet" : "Total Manual Deposit Net",
    "sumManualWithdraw" : "Total Manual Withdraw",
    "countManualWithdraw" : "Total Manual Withdraw Count",
    "sumManualWithdrawCom" : "Total Manual Withdraw Commission",
    "sumManualWithdrawNet" : "Total Manual Withdraw Net",
    "reportDate" : "Report Date",
    "changedBy" : "Changed Status by",
    "companyId" : "Company Id",
    "clientId" : "Client Id",
    "reason" : "Reason"
  },
  "select": {
    "status": "Select Status",
    "depositStatus": "Select Deposit Status",
    "withdrawtStatus": "Select Withdraw Status",
    "OTPstatus": "Select OTP Status",
    "OTPcode": "Select OTP Code",
    "company": "Select Company",
    "client": "Select Client",
    "role": "Select Role",
    "depositType": "Select Deposit Type",
    "withdrawType": "Select Withdraw Type",
    "withdrawStatus": "Select Withdraw Status",
    "vendor": "Select Vendor",
    "bank": "Select Bank",
    "type": "Select Type",
    "userType": "Select User Type",
    "redirectType": "Select Redirect Type",
    "startDate": "Start Date",
    "endDate": "End Date",
    "show": "Show",
    "clientGroup": "Select Client Group",
    "dateField": "Select Date Field",
    "changeStatus": "Change Status",
    "withClientGroups": "With Client Groups"
  },
  "settings": {
    "language": "Language",
    "generalPreferences": "General preferences",
    "theme": "Theme",
    "notifications": "Notifications"
  },
  "account": {
    "name": "Name",
    "email": "Email",
    "username": "Username",
    "role": "User Role",
    "type": "User Type",
    "memberSince": "Member Since",
    "company": "Company",
    "client": "Client",
    "password": "Password",
    "status": "Status",
    "statusText": "Superadmin can make it active or passive."
  },
  "table": {
    "name": "Name",
    "surname": "Surname",
    "email": "Email",
    "status": "Status",
    "OTPstatus": "OTP Status",
    "role": "Role",
    "company": "Company",
    "client": "Client",
    "clients": "Clients",
    "bank": "Bank",
    "createdAt": "Created At",
    "updatedAt": "Updated At",
    "depositStatus": "Deposit Status",
    "withdrawStatus": "Withdraw Status",
    "deposit": "Deposit",
    "depositAmount": "Deposit Amount",
    "depositLimit": "Deposit Limit",
    "limitStatus": "Limit Status",
    "bankCode": "Bank Code",
    "iban": "IBAN",
    "type": "Type",
    "action": "Action",
    "description": "Description",
    "amount": "Amount",
    "playerUsername": "Player Username",
    "playerFullname": "Player Name",
    "senderFullName": "Sender Name",
    "playerId": "Player Id",
    "receiver": "Receiver",
    "sender_iban": "Sender Iban",
    "receiver_iban": "Receiver Iban",
    "balance": "Balance",
    "risk": "Risk",
    "bank_name": "Bank Name",
    "code": "Code",
    "direction": "Direction",
    "sumDeposit" : "Deposit ",
    "sumDepositCom" : "Deposit Com",
    "sumDepositNet" : "Deposit Net",
    "sumWithdraw" : "Withdraw",
    "sumWithdrawCom" : "Withdraw Com",
    "sumWithdrawNet" : "Withdraw Net",
    "sumManualDeposit" : "Manual Deposit",
    "sumManualDepositCom" : "Manual Deposit Com",
    "sumManualDepositNet" : "Manual Deposit Net",
    "sumManualWithdraw" : "Manual Withdraw",
    "sumManualWithdrawCom" : "Manual Withdraw Com",
    "sumManualWithdrawNet" : "Manual Withdraw Net",
    "reportDate" : "Report Date",
    "requestData" : "Request Data",
    "webhookUrl" : "Webhook Url",
    "responseHttpStatus" : "Response Http Status",
    "response" : "Response",
    "responseDuration" : "Response Duration",
    "playerTransactionId" : "Player Transaction Id",
    "minDepositAmount": "Min Deposit Amount",
    "maxDepositAmount": "Max Deposit Amount",
    "select": "Select",
    "title": "Title"
  },
  "common": {
    "save": "Save",
    "approve": "Approve",
    "approved": "Approved",
    "decline": "Decline",
    "declined": "Declined",
    "waitingApprove": "Waiting Approve",
    "cancel": "Cancel",
    "remove": "Remove",
    "confirmDeleteTitle": "Confirm Delete",
    "confirmDeleteText": "Do you confirm delete?",
    "createUser": "Create User",
    "editUser": "Edit User",
    "createCompany": "Create Company",
    "editCompany": "Edit Company",
    "createClient": "Create Client",
    "editClient": "Edit Client",
    "editClientGroup": "Edit Client Group",
    "createBankAccount": "Create Bank Account",
    "editBankAccount": "Edit Bank Account",
    "createClientTransaction": "Create Client Transaction",
    "editClientTransaction": "Client Transaction Detail",
    "createRole": "Create Role",
    "editRole": "Edit Role",
    "createPlayerTransaction": "Create Player Transaction",
    "approvePlayerTransaction": "Approve Player Transaction",
    "checkPasswordsSame": "Passwords are not the same",
    "checkPasswordRules": "Password must contain at least 8 characters, one uppercase letter, one lowercase letter and one number.",
    "enabled2faText": "Two-Factor Authentication (2FA) is now enabled for your account, adding an extra layer of security to your sign-ins.",
    "disabled2faText": "Add an extra layer of security to your account. To sign in, you'll need to provide a code along with your username and password.",
    "setupKey": "Setup Key",
    "copy": "Copy",
    "text2faAuthentication": "Two-factor authentication",
    "detail": "Detail",
    "filters": "Filters",
    "sort": "Sort",
    "sortName": "Sort Name",
    "sortDir": "Sort Direction",
    "deposit": "Deposit",
    "withdraw": "Withdraw",
    "actionAssign": "Action Assign",
    "createBank": "Create Bank",
    "editBank": "Edit Bank",
    "createVendor": "Create Vendor",
    "editVendor": "Edit Vendor",
    "inputs": "Inputs",
    "outputs": "Outputs",
    "count": "Count",
    "playerDeposits": "Player Deposits",
    "currentDeposits": "Current Deposits",
    "totalDepositQuantity": "Total Deposit Quantity",
    "totalDepositAmount": "Total Deposit Amount",
    "totalDepositCommission": "Total Deposit Commission",
    "playerWithdraws": "Player Withdraws",
    "currentWithdraws": "Current Withdraws",
    "totalWithdrawQuantity": "Total Withdraw Quantity",
    "totalWithdrawAmount": "Total Withdraw Amount",
    "totalWithdrawCommission": "Total Withdraw Commission",
    "net": "Net",
    "tr": "TR",
    "totalRecord": "Total Record",
    "total": "Total",
    "adminPanel": "Admin Panel",
    "refreshPage": "There are new updates. Click to refresh.",
    "newManualDepositInfoText": "You deposit money. The amount you enter will be saved into the system.",
    "newManualWithdrawInfoText": "You withdraw money. The amount you enter will be saved into the system.",
    "allow": "Allow",
    "IOScheckHead" : "What to do for IOS Safari:",
    "IOScheckText" : "First add the page to the Home page, then click Allow notifications.",
    "clickForVisualHelp": "Click for visual help",
    "close": "Close",
    "revertDeclined": "Update status to waiting approval",
    "confirm": "Confirm",
    "confirmApproveAmount": "This amount Will be approved",
    "createClientGroup": "Create Client Group",
    "totalBalance": "Total Balance",
    "yesterdayClosingBalance": "Yesterday's Closing Balance",
    "successMsg": "Saved Successfully",
    "selectedIds": "Selected Ids",
    "selectAll": "Select All"
  }
}
