import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGlobalStore = defineStore('global', () => {

  const mobileMenuItems = [
    {
      name: 'Home',
      displayName: 'menu.home',
      meta: {
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      }
    },
    {
      name: 'PlayerTransactions',
      displayName: 'menu.playerTransactions',
      meta: {
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance']
      }
    },
    {
      name: 'Users',
      displayName: 'menu.users',
      meta: {
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin']
      }
    },
    {
      name: 'Companies',
      displayName: 'menu.companies',
      meta: {
        userType: ['admin', 'company'],
        userRole: ['super admin', 'admin', 'finance']
      }
    },
    {
      name: 'Clients',
      displayName: 'menu.clients',
      meta: {
        icon: 'account_circle',
        userType: ['admin'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'Clients',
          displayName: 'menu.clients',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'ClientGroups',
          displayName: 'menu.clientGroups',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
      ]
    },
    {
      name: 'BankAccounts',
      displayName: 'menu.bankAccounts',
      meta: {
        userType: ['admin', 'company'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      }
    },
    {
      name: 'Finance',
      displayName: 'menu.finance',
      meta: {
        icon: 'payments',
        userType: ['admin', 'client', 'company'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'ClientTransactions',
          displayName: 'menu.clientTransactions',
          meta: {
            userType: ['admin', 'client'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'CompanyTransactions',
          displayName: 'menu.companyTransactions',
          meta: {
            userType: ['admin', 'company'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'NewManualDeposit',
          displayName: 'menu.newManualDeposit',
          meta: {
            userType: ['admin', 'client'],
            userRole: ['super admin', 'admin', 'client']
          }
        },
        {
          name: 'NewManualWithdraw',
          displayName: 'menu.newManualWithdraw',
          meta: {
            userType: ['admin', 'client', 'company'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'CompanyBalances',
          displayName: 'menu.companyBalances',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'ClientBalances',
          displayName: 'menu.clientBalances',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
      ],
    },
    {
      name: 'Reports',
      displayName: 'menu.reports',
      meta: {
        icon: 'summarize',
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'ClientReports',
          displayName: 'menu.clientReports',
          meta: {
            userType: ['admin', 'client'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'CompanyReports',
          displayName: 'menu.companyReports',
          meta: {
            userType: ['admin', 'company'],
            userRole: ['super admin', 'admin', 'finance']
          }
        },
        {
          name: 'DepositWithdrawReports',
          displayName: 'menu.depositWithdrawReports',
          meta: {
            userType: ['admin', 'company', 'client'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'Notifications',
          displayName: 'menu.excelExport',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin']
          }
        }
      ],
    },
    {
      name: 'Settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
        userType: ['admin'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      },
      children: [
        {
          name: 'Banks',
          displayName: 'menu.banks',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'Vendors',
          displayName: 'menu.vendors',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
        {
          name: 'CallbackLogs',
          displayName: 'menu.callbackLogs',
          meta: {
            userType: ['admin'],
            userRole: ['super admin', 'admin', 'finance', 'reader']
          }
        },
      ],
    },
    {
      name: 'Account',
      displayName: 'menu.account',
      meta: {
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      }
    },
    {
      name: 'Settings',
      displayName: 'menu.userSettings',
      meta: {
        userType: ['admin', 'company', 'client'],
        userRole: ['super admin', 'admin', 'finance', 'reader']
      }
    },
  ]

  const languageCode = ref('tr')

  function hasAnyRole(role: string, type: string) {
    const userRole = localStorage.getItem('userRole')
    const userType = localStorage.getItem('userType')
    return role == userRole && type == userType
  }

  const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

  const perPage = ['20', '50', '100', '500', '1000']

  return {
    mobileMenuItems,
    languageCode,
    hasAnyRole,
    regexPassword,
    perPage
  }
});
